import React, { useState } from "react";
import classNames from "classnames";
import { NavLink, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSalesforce } from "@fortawesome/free-brands-svg-icons";
import { faChevronRight, faComment, faFlag, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { toast } from "react-toastify";

import CollapsingPills from "$components/table/cells/collapsing-pills";
import Comments from "$components/comments";
import CornerRibbon from "$components/corner-ribbon";
import EngineLoader from "$components/loaders/engine-loader";
import Feedback from "$components/feedback";
import Flags from "$components/flags";
import NoData from "$components/charts/no-content";
import ProbabilityBubble from "$components/probability";
import SalesforceOpportunityForm from "$components/salesforce/form";
import TeamMember from "$components/profile-icon/team-member";

import { dealProbabilityText } from "$utils/probability-text";
import { abbrNum, getNumberRange } from "$utils/number-helper";
import { fetchPotentialProjects } from "$stores/client-details";
import { getTerm } from "$stores/dictionary";
import { AppContextStore } from "$stores/app-context";
import { closeModal, initiateModal } from "$stores/modal";

import "./potential-projects.scss";

const ProjectPanel = ({ index, project, type }) => {
    const { clientId } = useParams();
    const appInsights = useAppInsightsContext();
    const [showComments, setShowComments] = useState(false);
    const [showFeedback, setShowFeedback] = useState(false);
    const [commentCountText, setCommentCountText] = useState(0);
    const revenueVariance = getNumberRange(project.averageRevenue);
    const { enableSalesForce, probabilityMatrix, enableExperiencedTeamMembers } = AppContextStore.useState((a) => {
        return { enableSalesForce: a.settings?.externalConnections?.salesforce, probabilityMatrix: a.siteConfig?.probabilityMatrix, enableExperiencedTeamMembers: a.settings?.sections?.experiencedTeamMembers || false };
    });

    const toggleComments = () => {
        setShowComments(!showComments);
        if (showFeedback) {
            setShowFeedback(false);
        }
    };

    const onSalesforceSubmit = () => {
        closeModal();
        fetchPotentialProjects({ client: clientId });
    };

    const toggleFeedback = () => {
        setShowFeedback(!showFeedback);
        if (showComments) {
            setShowComments(false);
        }
    };

    const openSalesforce = () => {
        if (project.hasSalesforceOpportunityCreated) {
            toast("This project has already been sent to Salesforce");
            return;
        }
        if (showFeedback) {
            setShowFeedback(false);
        }
        if (showComments) {
            setShowComments(false);
        }
        initiateModal(SalesforceOpportunityForm, { clientId: project.clientId, currentProject: project, projectKey: project.projectKey, afterSubmit: onSalesforceSubmit }, { analyticsLabel: "Salesforce" });
    };

    const trackProjectClick = () => {
        appInsights.trackEvent(
            { name: `Navigation - ${getTerm("potentialProjects")} Top 5` },
            {
                label: `${project.id} (Top ${index}) ${getTerm("potentialProject")}`,
                page: document.title.replace(" - Orgaimi", ""),
            }
        );
    };

    const updateCommentCount = (countText) => {
        setCommentCountText(countText);
    };

    return (
        <div className="potential-project">
            <header>
                <CornerRibbon text={index} />
                <Flags data={[{ classnames: { "-independence": project?.requiresIndependence }, label: "Check Independence" }]} />

                <h3>
                    {project.name}
                    <span>
                        <em>{getTerm("subClient")}:</em> {project.clientName}
                    </span>
                </h3>

                <NavLink to={`/project/${project.id}/potential`} onClick={trackProjectClick}>
                    <FontAwesomeIcon icon={faChevronRight} />
                </NavLink>
            </header>
            <div className="panel-content">
                {project.isCrossSell && <CollapsingPills data={[{ id: "cross-sell", text: "Cross-Sell", modifierClassNames: ["-importance"] }]} maxVisible={2} keyPrefix="cross-sell" analyticsLabel="Flags" />}
                {type === "revenue" && (
                    <>
                        <h4>
                            <em>
                                <sup>$</sup>
                                {abbrNum(revenueVariance.low, { showSuffix: true, decimalPrecision: 0 })}&ndash;{abbrNum(revenueVariance.high, { decimalPrecision: 0 })}
                                {false && (
                                    <sup>
                                        {" "}
                                        <FontAwesomeIcon icon={faExclamationTriangle} />
                                    </sup>
                                )}
                            </em>
                        </h4>
                        <h5 data-tooltip={getTerm("tooltipAverageProjectRevenue")}>{getTerm("averageRevenue")}</h5>
                        <hr />
                        <h5 data-tooltip={getTerm("tooltipExpansionProbability")}>
                            Expansion Probability: <em>{dealProbabilityText(project.probability, probabilityMatrix)}</em>
                        </h5>
                    </>
                )}

                {type === "probability" && (
                    <>
                        <div data-tooltip={getTerm("tooltipExpansionProbability")}>
                            <ProbabilityBubble label="Expansion Probability" value={project.probability} />
                        </div>
                        <hr />
                        <h5 data-tooltip={getTerm("tooltipAverageProjectRevenue")}>
                            {getTerm("averageRevenue")}:{" "}
                            <em>
                                <sup>$</sup>
                                {abbrNum(revenueVariance.low, { showSuffix: true, decimalPrecision: 0 })}&ndash;{abbrNum(revenueVariance.high, { decimalPrecision: 0 })}
                            </em>
                        </h5>
                    </>
                )}

                {project.projectTypeGroup && (
                    <>
                        <hr />
                        <h5>
                            {getTerm("projectTypeGroup")}: <em className="txt -white">{project.projectTypeGroup}</em>
                        </h5>
                    </>
                )}

                <hr />
                <h5>
                    {getTerm("serviceLine")}: <em className="txt -white">{project.serviceLine}</em>
                </h5>
                <hr />
                <h5>
                    {getTerm("industry")}: <em className="txt -white">{project.industry}</em>
                </h5>
                <hr />
                <h5>{getTerm("subCustomerRelationsManager")}</h5>
                <div className="team-members">
                    {/* Check this when we have data */}
                    <TeamMember data={{ id: project.crmId, name: project.crmName, email: project.crmEmail, isActive: project.crmIsActive }} />
                </div>
                {enableExperiencedTeamMembers && (
                    <>
                        <h5 data-tooltip={getTerm("tooltipExperiencedTeam")} style={{ marginTop: "20px" }}>
                            Experienced {getTerm("teamMembers")}
                        </h5>
                        <div className="team-members">
                            {project.team.slice(0, 3).map((m, i) => (
                                <TeamMember key={`member-${i}`} data={m} showExpertIn={true} showFeedback={true} projectId={project.id} projectKey={project.projectKey} />
                            ))}
                        </div>
                    </>
                )}
                <div className={classNames("comments-overlay", { "-active": showComments })}>
                    {project?.projectKey && <Comments projectKey={project.projectKey} analyticsLabel={getTerm("potentialProjects")} updateCommentCount={updateCommentCount} />}
                </div>
                <div className={classNames("feedback-overlay", { "-active": showFeedback })}>
                    <Feedback
                        id={project.id}
                        projectKey={project.projectKey}
                        onSubmit={() => {
                            setShowFeedback(false);
                            fetchPotentialProjects({ client: clientId });
                        }}
                        analyticsLabel={getTerm("potentialProject")}
                    />
                </div>
            </div>
            <footer className="dialogue-menu">
                <div className="comments">
                    <button className={classNames("button -text", { "-active": showComments })} onClick={toggleComments}>
                        <FontAwesomeIcon icon={faComment} /> {commentCountText}
                    </button>
                </div>
                <div className="button-group">
                    {!project.userFlagged && (
                        <button className={classNames("button -text", { "-active": showFeedback })} onClick={toggleFeedback}>
                            <FontAwesomeIcon icon={faFlag} />
                        </button>
                    )}
                    {enableSalesForce && (
                        <button className={classNames("button -text", { "-disabled": project.hasSalesforceOpportunityCreated })} onClick={openSalesforce}>
                            <FontAwesomeIcon icon={faSalesforce} />
                        </button>
                    )}
                    {project.externalResourceLinks &&
                        project.externalResourceLinks.map((l, i) => {
                            return (
                                <a key={`link_${l.type}_${i}`} className="button -text" href={l.url} target="_blank">
                                    <img src={`/images/icon-${l.type.toLowerCase()}.svg`} className="svg -detail" title={capitalizeFirstLetter(l.type)} width="20" />
                                </a>
                            );
                        })}
                </div>
            </footer>
        </div>
    );
};

const PotentialProjects = ({ projects, type }) => {
    if (!projects) {
        return <EngineLoader />;
    }
    if (projects.length <= 0) {
        return <NoData />;
    }
    return (
        <div className="potential-projects">
            {!projects && <h3 className="txt -center -gray">Well done! This client has no {getTerm("potentialProjects").toLowerCase()} at this time. Check back next month!</h3>}
            {projects && projects.map((p, i) => <ProjectPanel key={`${p.id}_${i}`} index={i + 1} project={p} type={type} />)}
        </div>
    );
};

export default PotentialProjects;
